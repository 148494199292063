import styled from "styled-components";

export default function EmailPreview () {
    return(
        <Container>
            
        </Container>
    )
};

const Container = styled.div`
    
`